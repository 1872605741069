// palette
$primary-light: #e0e0e0;
$primary-main: #adadad;
$primary-dark: #adadad;
$primary-text: #fff;

$secondary-light: #c0003c;
$secondary-main: #c0003c;
$secondary-dark: #c0003c;
$secondary-text: #4b4b4b;

$blue-background: #3e7cb1;
$blue-border: #2e6ca1;

$gray-text: #b1b1b1;
$gray-background: #b1b1b1;
$gray-border: #dfdfdf;

// variables
$max-width: 1100px;