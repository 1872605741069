#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header-image-container {
    @include flex(column, center, center, hidden);
    height: 500px;
}

.header-image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    min-width: 1500px;
    min-height: 700px;
    z-index: -1;
}

.banner {
    width: 100%;
    padding: 24px 0;
    margin: 16px 0;
    color: $primary-text;
    text-align: center;

    h4 {
        width: 100%;
        align-items: center;
        margin: 0 auto 16px;
        display: flex;

        &:before, &:after {
            content: " ";
            height: 1px;
            margin: 0 24px;
            flex: 1 1;
            background: $primary-text;
        }
    }

    p {
        font-size: 21px;
    }

    &:before {
        content: " ";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100vw;
        height: 100%;
        transform: translate(-50%, -50%);
        background: $primary-main;
        z-index: -1;
    }
}

.logo-wrapper {
    @include flex();
    height: 100px;

    &--simple {
        @include flex(row, center, flex-start);
    }
}

.logo-container, .nav {
    display: flex;
    width: 100%;
    max-width: $max-width;

    &--simple {
        align-items: center;
        max-width: initial;
    }
}

.logo-container {
    img {
        height: 100px;
    }

    &--simple {
        a {
            padding: 0 24px;
        }

        img {
            margin-left: 16px;
        }
    }
}

.logo {
    &--simple {
        width: auto;
        height: 60px;
        max-height: 60px;
    }
}

.nav-container {
    @include flex();
    background: $primary-main;
    position: sticky;
    top: 0;
    z-index: 2;

    &--simple {
        background: $gray-background;
    }
}

.nav {
    a, button, p {
        display: inline-flex;
        align-items: center;
        padding: 0 24px;
        line-height: 60px;
        color: $primary-text;
        background: transparent;
        border: none;
        font-size: 16px;
        text-decoration: none;
        transition: .15s ease-in-out;
        text-transform: capitalize;

        &:disabled {
            pointer-events: none;
            opacity: .5;
        }

        svg {
            font-size: 22px;
        }

        b {
            margin-left: 4px;
        }

        &::before {
            line-height: initial;
        }
    }

    a, button {
        cursor: pointer;
    }

    .active {
        background: $primary-dark;
    }

    .language-switch-container {
        margin-left: auto;
    }

    &--simple {
        flex-wrap: wrap;

        a, button, p {
            font-size: 15px;
            text-transform: initial;
        }

        a:hover {
            background-color: $primary-light;
            color: $primary-dark;
        }

        .language-switch-container {
            margin-left: 0;
        }
    }
}

.nav:not(.nav--simple) {
    a, button {
        &:hover {
            background: $primary-light;
            color: $primary-dark;
        }
    }
}

.footer-logo-container {
    @include flex(row, flex-start, space-between);
    max-width: $max-width;

    img {
        display: block;
        height: 100px;
    }

    .buttons-container {
        display: flex;
        column-gap: 15px;
    }
}

.studies-image {
    width: 100%;
    height: auto;
    max-height: 365px;
    object-fit: cover;
}

.main {
    @include flex();
    max-width: $max-width;
}

.progress-main {
    @include flex(column, flex-start);
    width: 100%;
    padding: 16px;
}

.score-block {
    max-width: 600px;
    padding: 48px 24px 24px;
    background: $gray-border;
    margin-left: 150px;

    img {
        position: absolute;
        left: 115%;
        top: 15%;
        height: 66%;
        width: auto;
    }

    .buttons-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.7;
}

#score-value {
    font-weight: bold;
    margin-right: auto;
}

.address-row {
    @include flex(row, center, flex-start);
}

.start-course-container {
    @include flex();
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.breadcrumbs {
    padding: 0 24px;

    a {
        font-size: 14px;
        color: $secondary-dark !important;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.course-link {
    display: inline-block;
}

.page-wrapper {
    flex-grow: 1;
}

label.question-label {
    font-size: 17px;
    font-weight: bold;
    color: #000;
}

div[name^="checkbox-group-"] {
    display: flex;
    flex-direction: column;
}

.footer-image-1 {
    height: 50px;
}

.footer-image-2 {
    height: 135px;
}

.questions-form {
    width: fit-content;
    margin: 0 auto;

    .checkmark {
        position: absolute;
        left: -22.5px;
        top: 2.5px;
        font-size: 20px;
    }

    .correct {
        .checkmark {
            color: green;
        }
    }

    .incorrect {
        .checkmark {
            color: red;
            font-weight: bold;
        }
    }
}

.answer-description {
  font-style: italic;
  margin-left: 22.5px;
  // margin-bottom: px;
  color: #595959;
}

div.timer {
    font-size: 20px;
    font-weight: bold;
    margin-top: -15px;
    margin-bottom: 5px;
}