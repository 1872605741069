@import 'theme';
// First mock's theme, then common styles
@import 'common-frontend/assets/scss';
@import 'pages';
@import 'components';

* {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: Arial MT, Arial , Helvetica, sans-serif;
  overflow-x: hidden;
}

.mt-16 {
  margin-top: 16px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-48 {
  margin-top: 48px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.notistack-MuiContent-info {
  background: #b1b1b1 !important;
}